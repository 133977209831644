@import '../styles/vars.css';

@keyframes fade-in {
  0% {
    opacity: 0;
    top: 2%;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

.article_wrapper {
  position: relative;
  height: 300vh; /* Length of the interactive scroll article */
  top: 0;
}

.canvas_wrapper {
  position: sticky;
  display: flex;
  top: 0;
  width: 100%;
  height: 100vh;
  align-content: center;
  opacity: 0;
  animation: fade-in 1.5s ease-in-out 0.5s forwards;
  animation-delay: 1.5s;
}

/* .menu_wrapper {
  position: sticky;
  z-index: 60;
  top: 0.5rem;
} */

.content_wrapper {
  position: absolute;
  z-index: 50;
  top: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 90vh;
  justify-content: center;
  text-decoration: none;
}

h1 {
  position: absolute;
  z-index: 50;
  font-family: var(--ff-gloock);
  font-weight: 400;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  color: var(--c-light-grey);
}

.card {
  display: flex;
  /* display: block; */
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 1.5vh;
  margin-left: 5rem;
}

.cover_img {
  position: fixed;
  transform: translateY(-50%) scale(1);
  width: auto;
  height: 30vh;
  opacity: 1;
  border-radius: 25px;
  /* transition: 0.1s; */
  pointer-events: none;
}

.hidden_cover_img {
  position: fixed;
  transform: translateY(-40%) scale(0.95);
  width: auto;
  height: 30%;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  border-radius: 25px;
}

.project_info {
  /* margin-left: 2.5rem; */
  display: block;
}

.project_title,
.list_title {
  font-family: var(--ff-roboto);
  font-weight: 400;
  color: var(--c-dark-grey);
  line-height: 7rem;
  letter-spacing: -0.2rem;
}

a {
  text-decoration: none;
}

.project_title {
  margin: 0;
  font-size: 6rem;
  transition: 0.2s;

  &:hover {
    color: var(--c-magenta);
    transition: 0.2s;
  }
}

p {
  font-family: var(--ff-roboto);
  margin: 0;
  color: var(--c-light-grey);
}

@media only screen and (max-width: 767px) {
  .project_title {
    margin: 0;
    font-size: 3rem;
    transition: 0.2s;
    line-height: 3rem;

    &:hover {
      color: var(--c-magenta);
      transition: 0.2s;
    }
  }

  .card {
    margin-left: 1rem;
  }
}
