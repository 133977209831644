@import './styles/vars.css';

@keyframes fade-in {
  0% {
    opacity: 0;
    top: 2%;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

body {
  background-color: rgb(248, 248, 248);
}

.navigationbar_wrapper {
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 60;
  top: 1.5rem;
  height: 0;
  margin-left: 3rem;
  margin-right: 3rem;
}

.footer_wrapper {
  display: flex;
  justify-content: flex-end;
  z-index: 60;
  margin-bottom: 1.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  gap: 3rem;
}

.menu_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
}

.menu_text {
  font-family: var(--ff-roboto);
  color: var(--c-dark-grey);
  font-size: 1.5rem;
  letter-spacing: -0.1rem;
  &:hover {
    color: var(--c-magenta);
    transition: 0.2s;
  }
}

.footer_text {
  font-family: var(--ff-roboto);
  color: var(--c-light-grey);
  font-size: 1rem;
  &:hover {
    color: var(--c-magenta);
    transition: 0.2s;
  }
}

.intro_wrapper {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  top: 5%;
  margin: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.contact_wrapper {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.projects_wrapper {
  position: absolute;
  z-index: 50;
  top: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 90vh;
  justify-content: center;
  text-decoration: none;
}

.contact_wrapper {
  top: 200vh;
}

.intro_header,
.contact_header {
  font-family: var(--ff-roboto);
  align-items: center;
  text-align: center;
  letter-spacing: -0.2rem;
  margin: 0;
  text-shadow: rgba(255, 255, 255, 0.8) 0 0 40px, rgba(255, 255, 255, 0.5) 0 0 80px;
}

.intro_header,
.contact_header {
  font-size: 7rem;
  color: var(--c-dark-grey);
  /* text-shadow: rgba(255, 255, 255, 0.5) 0 0 30px; */
  /* letter-spacing: -0.4rem; */
  font-weight: 1000;
}

.intro_paragraph {
  font-family: var(--ff-poppins);
  color: var(--c-dark-grey);
  font-size: 1.5rem;
  width: 70%;
  align-items: center;
  text-align: center;
  font-weight: 400;
  text-shadow: rgba(255, 255, 255, 0.5) 0 0 10px, rgba(255, 255, 255) 0 0 30px, rgba(255, 255, 255, 0.5) 0 0 60px,
    rgba(255, 255, 255, 0.8) 0 0 80px;
}

.contact_button {
  appearance: none;
  background-color: transparent;
  border: 2px solid var(--c-magenta);
  border-radius: 15px;
  box-sizing: border-box;
  color: var(--c-dark-grey);
  pointer-events: all;
  cursor: pointer;
  display: inline-block;
  font-family: var(--ff-roboto);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-top: 10px;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.contact_button:disabled {
  pointer-events: none;
}

.contact_button:hover {
  color: #fff;
  background-color: var(--c-magenta);
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.contact_button:active {
  box-shadow: none;
  transform: translateY(0);
}

.card {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  padding: 0.5rem;
  border-radius: 5px;
  margin: 1.5vh;
  margin-left: 5rem;
}

.caption {
  font-family: var(--ff-roboto);
  margin: 0;
  font-size: 1.3rem;
  color: var(--c-dark-grey);

  &:hover {
    color: var(--c-magenta);
    transition: 0.2s;
  }
}

.loading_screen_100,
.loading_screen_0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: opacity 1.5s;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-dark-grey);
  /* background-color: #fff; */
}

.loading_screen_100 {
  opacity: 1;
}

.loading_screen_0 {
  opacity: 0;
}

.loading_text {
  font-size: 2.5rem;
  font-weight: bold;
  /* color: #312e81; */
  color: #fff;
  position: relative;
}

.loading_progress {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.loading_background_text {
  opacity: 0.4;
}

@media only screen and (max-width: 767px) {
  .intro_header,
  .contact_header {
    font-size: 2.5rem;
  }

  .menu_wrapper {
    gap: 1.5rem;
  }

  .menu_text {
    font-size: 1rem;
  }

  .intro_paragraph {
    font-size: 1rem;
  }

  .navigationbar_wrapper {
    top: 1rem;
    height: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .card {
    margin-left: 1rem;
  }

  .footer_wrapper {
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 2rem;
  }
}
