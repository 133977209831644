@import '../styles/vars.css';

@keyframes fade-in {
  0% {
    opacity: 0;
    top: 2%;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

body {
  background-color: rgb(248, 248, 248);
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px;
  row-gap: 4rem;
  opacity: 0;
  animation: fade-in 1s ease-in-out 0.5s forwards;
}

.intro_wrapper,
.imgstrip_wrapper,
.info_wrapper,
.infoPage_wrapper,
.video_wrapper_landscape,
.image_wrapper_landscape,
.footer_nav_wrapper,
.filter_wrapper {
  width: 60%;
  max-width: 80%;
}

.intro_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.info_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 10px;
  row-gap: 10px;
}

.info_column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.info_column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.info_column ul li {
  margin-bottom: 0.1rem;
}

.paragraph_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
  max-width: 60%;
}

.tags {
  margin: 0;
  font-size: 1rem;
  /* color: var(--c-light-grey); */
  font-family: var(--ff-poppins);
  font-weight: 400;
}

.project_title {
  margin: 0;
  font-size: 5rem;
  color: rgb(0, 0, 0);
  font-family: var(--ff-roboto);
  font-weight: 400;
  letter-spacing: -0.3rem;
  line-height: 5rem;
}

.h1 {
  font-family: var(--ff-roboto);
  font-weight: 400;
  /* display: inline; */
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  margin: 0;
  /* margin-top: 5rem; */
  margin-bottom: 1rem;
  /* line-height: 5rem; */
}

h1 {
  font-family: var(--ff-roboto);
  display: contents;
  color: var(--c-dark-grey);
  font-size: 1.5rem;
  margin: 0;
  transition: 0.4s;
}

/* h1:hover {
  color: var(--c-magenta);
} */

p {
  font-family: var(--ff-poppins);
  color: rgb(0, 0, 0);
  font-size: 1rem;
  margin: 0;
  /* margin-top: 1rem; */
}

caption {
  font-size: 0.7rem;
}

.imgHero {
  width: 100%;
  height: 70vh;
}

.imgHero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgstrip_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5%;
  row-gap: 4rem;
  height: auto;
  max-height: 60%;
}

.video_wrapper_square,
.image_wrapper_square,
.video_wrapper_portrait,
.image_wrapper_portrait,
.video_wrapper_landscape,
.image_wrapper_landscape {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.video_wrapper_portrait,
.image_wrapper_portrait {
  height: 60vh;
  max-height: 80vh;
  max-width: 80%;
}

.video_wrapper_landscape,
.image_wrapper_landscape {
  height: auto;
}

.video_wrapper_square,
.image_wrapper_square {
  width: 45%;
  max-width: 85%;
}

.imgstrip_wrapper .imgstrip_wrapper {
  width: 45%;
}

.video_wrapper_square video,
.image_wrapper_square img,
.video_wrapper_landscape video,
.image_wrapper_landscape img,
.video_wrapper_square video,
.image_wrapper_square img {
  width: 100%;
  height: 100%;
}

.video_wrapper_portrait video,
.image_wrapper_portrait img {
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 80vh;
}

.footer_nav_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.footer_nav_text {
  font-family: var(--ff-roboto);
  font-size: 1.2rem;
  color: var(--c-dark-grey);
  &:hover {
    color: var(--c-magenta);
    transition: 0.2s;
  }
}

.filter_wrapper {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

button {
  appearance: none;
  background-color: transparent;
  border: 2px solid var(--c-magenta);
  border-radius: 15px;
  box-sizing: border-box;
  color: var(--c-dark-grey);
  pointer-events: all;
  cursor: pointer;
  display: inline-block;
  font-family: var(--ff-roboto);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-top: 10px;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
}

.projects_wrapper {
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2rem;
}

.project_wrapper {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project_wrapper_image {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  border-radius: 25px;
}

.project_wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s;
}

.overlay_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
  z-index: 1; /* Ensure the video appears on top of the image */
  opacity: 0;
  transition: 0.4s;
}

.project_wrapper:hover img {
  transform: scale(1.05);
}

.project_wrapper:hover .overlay_video {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .content_wrapper {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 1rem;
    margin-right: 1rem;
    row-gap: 4rem;
  }

  .intro_wrapper,
  .imgstrip_wrapper,
  .info_wrapper,
  .video_wrapper_landscape,
  .image_wrapper_landscape,
  .paragraph_wrapper,
  .footer_nav_wrapper {
    width: 85%;
    max-width: 85%;
  }

  .video_wrapper_portrait,
  .image_wrapper_portrait {
    height: auto;
    max-height: 80vh;
  }

  .video_wrapper_square,
  .image_wrapper_square {
    width: 100%;
  }

  .project_title {
    font-size: 3rem;
  }

  .filter_wrapper {
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    column-gap: 0.2rem;
    flex-wrap: wrap;
    flex-direction: row;
  }
  button {
    margin: 0;
    width: 100%;
    margin-top: 0.5rem;
    padding: 12px 16px;
  }

  .projects_wrapper {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 2rem;
  }

  .project_wrapper {
    width: 100%;
  }

  .project_wrapper_image {
    height: 35vh;
    border-radius: 25px;
  }

  .infoPage_wrapper {
    width: 85%;
    max-width: 85%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .infoPage_wrapper .paragraph_wrapper {
    width: 100%;
    max-width: 100%;
  }
  .infoPage_wrapper .image_wrapper_portrait {
    width: 100%;
    min-width: 100%;
    height: 35vh;
  }

  .image_wrapper_portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
  }

  .project_wrapper .overlay_video {
    display: none;
  }
}

.infoPage_wrapper {
  display: flex;
  column-gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.infoPage_wrapper .image_wrapper_landscape,
.infoPage_wrapper .image_wrapper_porttrait {
  width: 100%;
  height: auto;
}

.imgstrip_wrapper .video_wrapper_landscape {
  width: 47.5%;
}
