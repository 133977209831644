:root {
  /* color */
  --c-magenta: rgb(145, 84, 178);
  --c-light-grey: rgb(157, 157, 157);
  --c-dark-grey: rgb(45, 39, 85);

  /* font-families */
  --ff-roboto: 'Roboto Mono', monospace;
  --ff-poppins: 'Poppins', san-serif;
}
